<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div
            class="card-body d-flex justify-content-start flex-wrap space-x-3"
          >
            <div class="max-w-200px">
              <label for="">التاريخ من</label>
              <input
                class="form-control"
                id="date_from"
                type="date"
                v-model="date_from"
              />
            </div>
            <div class="max-w-200px">
              <label for="">التاريخ إلى</label>
              <input
                class="form-control"
                id="date_to"
                type="date"
                v-model="date_to"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <Line
                  v-if="registered.labels.length > 0"
                  :chart-options="chartOptions"
                  :chart-data="registered"
                  chart-id="registered"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                />
              </div>

              <div class="col-md-6">
                <Line
                  v-if="active.labels.length > 0"
                  :chart-options="chartOptions"
                  :chart-data="active"
                  chart-id="active"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import charts from "@/mixins/charts";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  ChartJsPluginDataLabels
);

export default {
  name: "Users",
  components: { Line },
  mixins: [charts],
  watch: {
    async date_from() {
      await this.setData();
    },
    async date_to() {
      await this.setData();
    },
  },
  data() {
    return {
      date_from: new Date(new Date().getFullYear(), 0, 1)
        .toISOString()
        .substring(0, 10),
      date_to: new Date(new Date().getFullYear(), 11, 31)
        .toISOString()
        .substring(0, 10),
      registered: {
        labels: [],
        datasets: [
          {
            label: "مستخدمين قاموا بالتسجيل",
            backgroundColor: "#007bff",
            data: [],
          },
        ],
      },
      active: {
        labels: [],
        datasets: [
          {
            label: "مستخدمين قامو بالمزاودة",
            backgroundColor: "#007bff",
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    async setData() {
      const data = {
        _method: "GET",
        date_from: this.date_from,
        date_to: this.date_to,
      };
      this.registered.labels = [];
      this.registered.datasets[0].data = [];
      this.active.labels = [];
      this.active.datasets[0].data = [];

      const response = await http.send("charts/users", data);
      if (http.responseAccepted(response)) {
        for (const item of response.data.registered) {
          this.registered.labels.unshift(item.month);
          this.registered.datasets[0].data.unshift(item.registered);
        }

        for (const item of response.data.active) {
          this.active.labels.unshift(item.month);
          this.active.datasets[0].data.unshift(item.active);
        }
      }
    },
  },
  async created() {
    await this.setData();
  },
};
</script>
